import React, { Component } from 'react';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'material-ui/Table';

const styles = theme => ({
    timeCell: {
        whiteSpace: 'nowrap',
    }
});

export class AttendeeDetailsDialog extends Component {
    renderActivity(activity, activityKey) {
        const {
            classes,
            activities,
            companies,
            rooms,
        } = this.props;

        return (
            <TableRow key={activityKey}>
                <TableCell>{activity.get('title')}</TableCell>
                <TableCell>{activity.get('type')}</TableCell>
                <TableCell>{companies.getIn([activities.getIn([activityKey, 'company_id']), 'name'])}</TableCell>
                <TableCell>{rooms.getIn([activities.getIn([activityKey, 'room_id']), 'name'])}</TableCell>
                <TableCell className={classes.timeCell}>
                    {activity.get('time_from').substring(0, 5)} - {activity.get('time_to').substring(0, 5)}
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const {
            attendee,
            toggleAttendeeDetailsDialog,
            isOpen,
            classes,
            activities,
            companies,
            rooms,
        } = this.props;
        
        if (!activities.size || !companies.size || !rooms.size ) {
            return null;
        }
        
        const assignments = attendee.get('assignments').filter((assignment) => assignment.confirmed !== 0)
        const confirmedAssignments = assignments.filter((assignment) => assignment.confirmed === 1)
        const unconfirmedAssignments = assignments.filter((assignment) => assignment.confirmed !== 1)

        return (
            <Dialog
                open={isOpen}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">{attendee.get('name')} {attendee.get('surname')}</DialogTitle>
                <DialogContent>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Activity name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Room</TableCell>
                                <TableCell>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attendee.has('assignments') ? (
                                <>
                                    <TableRow className="h-25">
                                        <TableCell colSpan={5} className="text-left font-weight-bold bg-light">Confirmed attendance</TableCell>
                                    </TableRow>
                                    {confirmedAssignments.size
                                        ? confirmedAssignments.keySeq().map((key) => this.renderActivity(activities.get(key), key))
                                        : (
                                            <TableRow>
                                                <TableCell colSpan={5} className="text-center">No records</TableCell>
                                            </TableRow>
                                        )
                                    }

                                    <TableRow className="h-25">
                                        <TableCell colSpan={5} className="text-left font-weight-bold bg-light">Unconfirmed attendance</TableCell>
                                    </TableRow>
                                    {unconfirmedAssignments.size
                                        ? unconfirmedAssignments.keySeq().map((key) => this.renderActivity(activities.get(key), key))
                                        : (
                                            <TableRow>
                                                <TableCell colSpan={5} className="text-center">No records</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </>
                            )
                                :
                                    <TableRow>
                                        <TableCell colSpan={5} className="text-center">No activities was assigned</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleAttendeeDetailsDialog(null)} color="primary">
                    close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AttendeeDetailsDialog = withStyles(styles)(AttendeeDetailsDialog);

export default connect(state => ({
}))(AttendeeDetailsDialog);
